import { getCurrentStep } from '../../../helpers';
/**
 * Init Carousel (slideChange + slideChangeTransitionEnd)
 * @param {Function} slideChangeCallback - Callback function to run on slideChange
 * @param {Function} transitionEndCallback - Callback function to run on slideChangeTransitionEnd
 */
export function initCarousel(slideChangeCallback = () => {}, transitionEndCallback = () => {}) {
	const allButtons = document.querySelectorAll('button');

	if (!window.modForm.opts.formSwiperInstance) {
		return;
	}

	window.modForm.opts.formSwiperInstance.on('slideChange', (e) => {
		allButtons.forEach(function(button) {
			button.disabled = true;
		});

		slideChangeCallback(e);
	});

	window.modForm.opts.formSwiperInstance.on('slideChangeTransitionEnd', (e) => {
		const activeStep = getCurrentStep();

		allButtons.forEach(function(button) {
			button.disabled = false;
		});

		if (activeStep) {
			activeStep.setAttribute('aria-hidden', 'false');
			activeStep.focus();
		}

		window.modForm.opts.form.querySelectorAll('.step').forEach((step) => {
			if (step !== activeStep) {
				step.setAttribute('aria-hidden', 'true');
			}
		});

		transitionEndCallback(e);

		window.modForm.opts.formSwiperInstance.update();
	});
}
