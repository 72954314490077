import { appendLeadTokenScriptToBody } from '../../helpers';
/**
 * Load lead token scripts (TrustedForm, Journaya) triggers via GTM's data layer
 */
export function loadLeadTokenScriptsTriggers() {
	const isModernizeDomain = window.modUtils.getLandingPage().indexOf('modernize.com') > 0 && !window.modUtils.getLandingPage().includes('bestcompany');

	if (window.modForm.areLeadTokenScriptsLoaded) {
		window.modForm.opts.form.querySelectorAll('.btn-primary, .btn--primary, input[type="radio"]').forEach(function(element) {
			if (!element) {
				return;
			}

			element.removeEventListener('click', function(e) {
				e.stopPropagation();
				loadLeadTokenScriptsTriggers();
			});
		});
		return;
	}

	const $body = document.querySelector('body');
	const isQSPage = window.modForm.opts.useQSApi;
	let scriptsURL = isModernizeDomain ? '' : '/';
	const scriptsFolder = window.modForm.opts.isVite ? 'resources/scripts/' : 'scripts/';

	if (window.isWordpress) {
		scriptsURL = window.modUtils.getLandingPage().indexOf('modernize.local') > 0 ? '/wp-content/themes/modernize/source/' : '/wp-content/themes/modernize/dist/';
	}
	if (window.modUtils.getEnv() !== 'local' && window.modForm.opts.landingPageSubdirectory !== '') {
		scriptsURL = window.modForm.opts.landingPageSubdirectory;
	}

	// When TrustedForm token input gets appended to the body
	const trustedFormObserver = new MutationObserver(function(mutations, observer) {
		if (document.contains(document.querySelector('[name=xxTrustedFormToken]'))) {
			window.modForm.loadedScripts.push('trusted');
			if (
				(isQSPage && window.modForm.loadedScripts.length === 1) // QS pages only
				|| (window.modForm.loadedScripts.length === 2)
			) {
				window.modForm.areLeadTokenScriptsLoaded = true;
			}
			observer.disconnect();
		}
	});

	trustedFormObserver.observe($body, { childList: true, subtree: true });

	if (!window.modForm.opts.disableRecaptcha) {
		// When Recaptcha token input gets appended to the body
		const recaptchaFormObserver = new MutationObserver(function(mutations, observer) {
			if (document.contains(document.querySelectorAll('.grecaptcha-badge')[0])) {
				window.modForm.loadedScripts.push('recaptcha');
				if (window.modForm.loadedScripts.length === 2) {
					window.modForm.areLeadTokenScriptsLoaded = true;
				}
				observer.disconnect();
			}
		});

		recaptchaFormObserver.observe($body, { childList: true, subtree: true });
	}

	if (!window.modForm.opts.useContractorApi) {
		// Load TrustedForm Script + If script is being injected again, remove it first
		if (document.querySelector('#trustedFormScript')) {
			document.querySelector('#trustedFormScript').nextElementSibling.closest('noscript').remove();
			document.querySelector('#trustedFormScript').remove();
		}
		appendLeadTokenScriptToBody(`${scriptsURL}${scriptsFolder}trusted-form.html`);

		// Load Recaptcha Script
		if ((!isQSPage || isModernizeDomain) && !window.modForm.opts.disableRecaptcha) {
			appendLeadTokenScriptToBody(`${scriptsURL}${scriptsFolder}recaptcha.html`);
		}
	}
}
