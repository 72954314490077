/**
 * Default quad values based on page domain
 * @type {Object}
 */
export const defaultQuadLinkValues = {
	// TODO: Remove m. values since we only use the desktop url
	'modernize.com': {
		medicalalerts: {
			c: '1677934',
			s: '31135',
			f: '202109081618210'
		},
		showers: {
			c: '1683621',
			s: '3086',
			f: '202309141602090'
		},
		default: {
			c: '1679227',
			s: '29914',
			f: '202202161521310'
		}
	},
	'modernizesolar.com': {
		c: '1679227',
		s: '29914',
		f: '202202161521310'
	},
	'modernize-solar.com': {
		c: '1679227',
		s: '29914',
		f: '202202161521310'
	},
	'go.jacuzzi.com': {
		c: '1674074',
		s: '3086',
		f: '202011031614490'
	},
	'm.go.jacuzzi.com': {
		c: '1674074',
		s: '3086',
		f: '202011031614490'
	},
	'americanhomewarranty.org': {
		c: '1673835',
		s: '3086',
		f: '202010191645170'
	},
	'm.americanhomewarranty.org': {
		c: '1673835',
		s: '3086',
		f: '202010191645170'
	},
	'homewindowprices.org': {
		c: '1673838',
		s: '3086',
		f: '202010191645180'
	},
	'm.homewindowprices.org': {
		c: '1673838',
		s: '3086',
		f: '202010191645180'
	},
	'mygutterguards.com': {
		c: '1673836',
		s: '3086',
		f: '202010191645180'
	},
	'm.mygutterguards.com': {
		c: '1673836',
		s: '3086',
		f: '202010191645180'
	},
	'tophottubs.org': {
		c: '1673840',
		s: '3086',
		f: '202010191645190'
	},
	'm.tophottubs.org': {
		c: '1673840',
		s: '3086',
		f: '202010191645190'
	},
	'americanstandardwalkinbaths.com': {
		c: '1665214',
		s: '28354',
		f: '201906181327550'
	},
	'm.americanstandardwalkinbaths.com': {
		c: '1665215',
		s: '28354',
		f: '201906181327560'
	},
	'bathandshower.org': {
		c: '1667476',
		s: '26393',
		f: '201910011622070'
	},
	'm.bathandshower.org': {
		c: '1667460',
		s: '26401',
		f: '201910011622030'
	},
	'bathandshowerpros.com': {
		c: '1658267',
		s: '26393',
		f: '201809211357520'
	},
	'm.bathandshowerpros.com': {
		c: '1658263',
		s: '26401',
		f: '201809211352160'
	},
	'quotes.bathwraps.com': {
		c: '1660429',
		s: '26393',
		f: '201812121510250'
	},
	'm.bathwraps.com': {
		c: '1660426',
		s: '26401',
		f: '201812121509080'
	},
	'craftmaticbeds.com': {
		c: '655234',
		s: '3086',
		f: '201008201638230'
	},
	'emedicalalerts.com': {
		c: '1647425',
		s: '26393',
		f: '201711071755250'
	},
	'm.emedicalalerts.com': {
		c: '1647405',
		s: '26401',
		f: '201711071755190'
	},
	'flooringpricer.com': {
		c: '1668109',
		s: '26393',
		f: '201910231539300'
	},
	'm.flooringpricer.com': {
		c: '1668101',
		s: '26401',
		f: '201910231539280'
	},
	'findyourwindows.com': {
		c: '1654347',
		s: '26393',
		f: '201805111614390'
	},
	'm.findyourwindows.com': {
		c: '1654331',
		s: '26401',
		f: '201805111612300'
	},
	'homesecurityprices.org': {
		c: '1658310',
		s: '26393',
		f: '201809211526510'
	},
	'm.homesecurityprices.org': {
		c: '1658305',
		s: '26401',
		f: '201809211509250'
	},
	'quotes.improvementcenter.com': {
		bathrooms: {
			c: '1652517',
			s: '26393',
			f: '201803221337450'
		},
		gutters: {
			c: '1614214',
			s: '3086',
			f: '201608311104250'
		},
		stairlifts: {
			c: '1658469',
			s: '26393',
			f: '201809260926260'
		},
		walkintubs: {
			c: '1647588',
			s: '26393',
			f: '201711081408530'
		}
	},
	'bathrooms.improvementcenter.com': {
		c: '1652509',
		s: '26401',
		f: '201803221337420'
	},
	'gutters.improvementcenter.com': {
		c: '1635550',
		s: '16479',
		f: '201703010604120'
	},
	'stairlifts.improvementcenter.com': {
		c: '1658465',
		s: '26401',
		f: '201809260926240'
	},
	'walkintubs.improvementcenter.com': {
		c: '1647564',
		s: '26401',
		f: '201711081408460'
	},
	'kitchensandcabinets.com': {
		c: '1652648',
		s: '26393',
		f: '201803261349020'
	},
	'm.kitchensandcabinets.com': {
		c: '1652624',
		s: '26401',
		f: '201803261348550'
	},
	'leafgutterpros.com': {
		c: '1685217',
		s: '3086',
		f: '202403271348130'
	},
	'lifemedicalalerts.com': {
		c: '1647427',
		s: '26393',
		f: '201711071755260'
	},
	'm.lifemedicalalerts.com': {
		c: '1647407',
		s: '26401',
		f: '201711071755200'
	},
	'go.medicalguardian.com': {
		c: '1654232',
		s: '26393',
		f: '201805101622250'
	},
	'mysolarcost.com': {
		c: '1645537',
		s: '3086',
		f: '201709221027450'
	},
	'm.mysolarcost.com': {
		c: '1645592',
		s: '16479',
		f: '201709221044340'
	},
	'roofingcosts.org': {
		c: '1673843',
		s: '3086',
		f: '202010191645200'
	},
	'm.roofingcosts.org': {
		c: '1673843',
		s: '3086',
		f: '202010191645200'
	},
	'securitypricer.com': {
		c: '1673856',
		s: '3086',
		f: '202010191645230'
	},
	'm.securitypricer.com': {
		c: '1673856',
		s: '3086',
		f: '202010191645230'
	},
	'walkinbathtubs.org': {
		c: '1673846',
		s: '3086',
		f: '202010191645210'
	},
	'm.walkinbathtubs.org': {
		c: '1673846',
		s: '3086',
		f: '202010191645210'
	},
	'walkintubprices.org': {
		c: '1673842',
		s: '3086',
		f: '202010191645200'
	},
	'm.walkintubprices.org': {
		c: '1673842',
		s: '3086',
		f: '202010191645200'
	},
	'walkintubforseniors.com': {
		c: '1647590',
		s: '26393',
		f: '201711081408540'
	},
	'm.walkintubforseniors.com': {
		c: '1647566',
		s: '26401',
		f: '201711081408470'
	},
	'plans.selecthomewarranty.com': {
		c: '1674075',
		s: '3086',
		f: '202011031614490'
	},
	'plumbingservicecosts.com': {
		c: '1684673',
		s: '3086',
		f: '202402061224360'
	},
	'm.plans.selecthomewarranty.com': {
		c: '1674075',
		s: '3086',
		f: '202011031614490'
	},
	'powerhomesolarpanels.com': {
		c: '1676761',
		s: '3086',
		f: '202105251631450'
	},
	'hvacpros.org': {
		c: '1670084',
		s: '26393',
		f: '202002181631370'
	},
	'm.hvacpros.org': {
		c: '1670076',
		s: '26401',
		f: '202002181625140'
	},
	'homewarrantyplans.org': {
		c: '1668109',
		s: '26393',
		f: '201910231539300'
	},
	'm.homewarrantyplans.org': {
		c: '1667462',
		s: '26401',
		f: '201910011622030'
	},
	'gutterguardcovers.com': {
		c: '1679714',
		s: '3086',
		f: '202205131023370'
	},
	'windowreplacementcost.org': {
		c: '1679716',
		s: '3086',
		f: '202205131023370'
	},
	'stairliftchairs.org': {
		c: '1679715',
		s: '3086',
		f: '202205131023370'
	},
	'roofingreplacementcost.com': {
		c: '1680434',
		s: '3086',
		f: '202208031707470'
	},
	'furnacereplacementcosts.org': {
		c: '1680996',
		s: '3086',
		f: '202209290958330'
	},
	'solarpanelpricing.com': {
		c: '1682247',
		s: '3086',
		f: '202302031201300'
	},
	'kitchencabinetrefacing.org': {
		c: '1680433',
		s: '3086',
		f: '202208031707460'
	},
	'airconditioningpros.org': {
		c: '1682333',
		s: '3086',
		f: '202302141453580'
	},
	'treetrimmingservicepros.com': {
		c: '1682327',
		s: '3086',
		f: '202302141447090'
	},
	'compareflooringprices.com': {
		c: '1683170',
		s: '3086',
		f: '202306161210090'
	},
	'sidingreplacementcost.com': {
		c: '1683497',
		s: '3086',
		f: '202308211539090'
	},
	'adt-savings.com': {
		c: '1683323',
		s: '31135',
		f: '202307211631550'
	},
	'topgutterpros.com': {
		c: '1683689',
		s: '3086',
		f: '202309251220170'
	},
	'topremodelingpros.com': {
		c: '1683689',
		s: '3086',
		f: '202309251220170'
	},
	'powerhomedeals.com': {
		roofing: {
			c: '1683681',
			s: '3086',
			f: '202309251210110'
		},
		windows: {
			c: '1683686',
			s: '3086',
			f: '202309251219100'
		},
		siding: {
			c: '1683683',
			s: '3086',
			f: '202309251214450'
		}
	},
	'bestcompany.modernize.com': {
		homewarranty: {
			c: '1684628',
			s: '34486',
			f: '202401301751220'
		},
		medicalalertsystems: {
			c: '1684826',
			s: '34486',
			f: '202402151655050'
		},
		homesecurity: {
			c: '1684824',
			s: '34486',
			f: '202402151653100'
		},
		solar: {
			c: '1684620',
			s: '34486',
			f: '202401301731070'
		},
		watersofteners: {
			c: '1684830',
			s: '34486',
			f: '202402141206230'
		},
		plumbing: {
			c: '1684766',
			s: '34486',
			f: '202402141115530'
		},
		treeservices: {
			c: '1684764',
			s: '34486',
			f: '202402141113540'
		},
		newwindows: {
			c: '1684828',
			s: '34486',
			f: '202402151656490'
		},
		walkintubs: {
			c: '1684760',
			s: '34486',
			f: '202402141109100'
		},
		stairlifts: {
			c: '1684758',
			s: '34486',
			f: '202402141106430'
		},
		siding: {
			c: '1684756',
			s: '34486',
			f: '202402141103210'
		},
		roofing: {
			c: '1684754',
			s: '34486',
			f: '202402141059590'
		},
		hvac: {
			c: '1684752',
			s: '34486',
			f: '202402141057220'
		},
		hottubs: {
			c: '1684750',
			s: '34486',
			f: '202402141054450'
		},
		gutters: {
			c: '1684748',
			s: '34486',
			f: '202402141051430'
		},
		flooring: {
			c: '1684746',
			s: '34486',
			f: '202402141049110'
		},
		doors: {
			c: '1684744',
			s: '34486',
			f: '202402141046440'
		},
		cabinets: {
			c: '1684822',
			s: '34486',
			f: '202402151651120'
		},
		bathroomremodel: {
			c: '1684820',
			s: '34486',
			f: '202402151646230'
		},
		findapro: {
			c: '1684895',
			s: '3086',
			f: '202402231339130'
		}
	},
	'bestcompany.com': {
		homewarranty: {
			c: '1684628',
			s: '34486',
			f: '202401301751220'
		},
		medicalalertsystems: {
			c: '1684826',
			s: '34486',
			f: '202402151655050'
		},
		homesecurity: {
			c: '1684824',
			s: '34486',
			f: '202402151653100'
		},
		solar: {
			c: '1684620',
			s: '34486',
			f: '202401301731070'
		},
		watersofteners: {
			c: '1684830',
			s: '34486',
			f: '202402141206230'
		},
		plumbing: {
			c: '1684766',
			s: '34486',
			f: '202402141115530'
		},
		treeservices: {
			c: '1684764',
			s: '34486',
			f: '202402141113540'
		},
		newwindows: {
			c: '1684828',
			s: '34486',
			f: '202402151656490'
		},
		walkintubs: {
			c: '1684760',
			s: '34486',
			f: '202402141109100'
		},
		stairlifts: {
			c: '1684758',
			s: '34486',
			f: '202402141106430'
		},
		siding: {
			c: '1684756',
			s: '34486',
			f: '202402141103210'
		},
		roofing: {
			c: '1684754',
			s: '34486',
			f: '202402141059590'
		},
		hvac: {
			c: '1684752',
			s: '34486',
			f: '202402141057220'
		},
		hottubs: {
			c: '1684750',
			s: '34486',
			f: '202402141054450'
		},
		gutters: {
			c: '1684748',
			s: '34486',
			f: '202402141051430'
		},
		flooring: {
			c: '1684746',
			s: '34486',
			f: '202402141049110'
		},
		doors: {
			c: '1684744',
			s: '34486',
			f: '202402141046440'
		},
		cabinets: {
			c: '1684822',
			s: '34486',
			f: '202402151651120'
		},
		bathroomremodel: {
			c: '1684820',
			s: '34486',
			f: '202402151646230'
		},
		findapro: {
			c: '1684895',
			s: '3086',
			f: '202402231339130'
		}
	},
	'topsolarcompanies.com': {
		c: '1684497',
		s: '34399',
		f: '202401241741100'
	},
	'besthomesecuritycompanys.com': {
		c: '1684495',
		s: '34399',
		f: '202401241738220'
	},
	'homewatersystemspros.com': {
		c: '1684679',
		s: '3086',
		f: '202402061238410'
	},
	'foundationandbasements.com': {
		c: '1685478',
		s: '3086',
		f: '202405021159440'
	},
	'topinsulationpros.com': {
		c: '1686466',
		s: '3086',
		f: '202408211814210'
	},
	'getwaterdamagehelp.com': {
		c: '1686734',
		s: '3086',
		f: '202409231230430'
	},
	'callrestorationpro.com': {
		'mold-damage': {
			c: '1687036',
			s: '3086',
			f: '202410171155570'
		},
		'water-damage': {
			c: '1687043',
			s: '3086',
			f: '202410171202560'
		},
		sewage: {
			c: '1687040',
			s: '3086',
			f: '202410171201130'
		},
		'fire-smoke-damage': {
			c: '1687033',
			s: '3086',
			f: '202410171150310'
		}
	},
	'localgaragedoorpros.org': {
		c: '1687046',
		s: '3086',
		f: '202410171204190'
	},
	'dabellaroofers.com': {
		c: '1687219',
		s: '35730',
		f: '202411010712090'
	},
	'callplumber247.com': {
		c: '1687378',
		s: '3086',
		f: '202411151459290'
	},
	'localbathremodelcost.com': {
		c: '1687744',
		s: '3086',
		f: '202501071443360'
	},
	'arsairconditioning.com': {
		c: '1687884',
		s: '35730',
		f: '202501171139360'
	},
	'leafguardpros.com': {
		c: '1687885',
		s: '35730',
		f: '202501171141140'
	},
	'homepromobilitystairlifts.com': {
		c: '1688047',
		s: '35730',
		f: '202502061236280'
	}
};
