/**
 * Get the prev step id
 *
 * @param {string} newStepId - current step id
 * @returns {string} - prev step id
 */
export function getPrevStepId(newStepId) {
	const $previousSibling = document.getElementById(newStepId).previousElementSibling;
	if ($previousSibling) {
		return $previousSibling.getAttribute('id');
	}
}
