/**
 * Returns skip step config
 * @example
 * ```{
 *  TRADE_NAME: {
 *   STEP_NAME_TO_BE_SKIPPED(PARENT_STEP): [
 *      {
 *        STEP_NAME_FROM_WHICH_TO_SKIP_PARENT: [VALUES_TO_SKIP]
 *      },
 *      {
 *        STEP_NAME_FROM_WHICH_TO_SKIP_PARENT: [VALUES_TO_SKIP]
 *      }
 *    ]
 *  },
 *  STEP_NAME_TO_BE_SKIPPED(PARENT_STEP): [
 *      {
 *        STEP_NAME_FROM_WHICH_TO_SKIP_PARENT: [VALUES_TO_SKIP]
 *      }
 *    ]
 *  }
 * }
 * ```
 * @returns {object} Config object
 */
export function getSkipStepsConfig() {
	let result = {};
	const { opts } = window.modForm;
	const { skipStepVertical, vertical } = opts;

	const config = {
		HVAC: {
			BoilerSystemInstallType: [ // Step name that need to skip
				{
					// Add step name as key & array of values, to skip the step based on it.
					HVACInterest: ['Central AC', 'Central Heating', 'Central Heating Heat Pump']
				}
			],
			CentralHeatingInstallType: [
				{
					HVACInterest: ['Central AC', 'Boiler/Radiator']
				}
			],
			BoilerHeatingInstallRepairType: [ // Step name that need to skip
				{
					HVACInterest: ['Central AC'] // Add step name as key & array of values, to skip the step based on it.
				}
			]
		},
		CABINET_REFACING: {
			OptIn: [
				{
					ProductService: ['CABINET_INSTALL', 'CABINET_REFACING', 'COUNTERTOP']
				}
			],
			CountertopType: [
				{
					ProductService: ['KITCHEN_REMODEL', 'CABINET_INSTALL', 'CABINET_REFACING']
				}
			],
			CountertopProjectScope: [
				{
					ProductService: ['KITCHEN_REMODEL', 'CABINET_INSTALL', 'CABINET_REFACING']
				}
			],
			CommercialLocation: [
				{
					ProductService: ['KITCHEN_REMODEL', 'CABINET_INSTALL', 'CABINET_REFACING']
				}
			],
			BuyTimeframe: [
				{
					ProductService: ['KITCHEN_REMODEL', 'CABINET_INSTALL', 'CABINET_REFACING']
				}
			]
		},
		WINDOWS: {
			MultipleWindows: [
				{
					NumberOfWindows: ['2', '3-5', '6-9']
				}
			]
		},
		BATHROOM_REFACING: {
			OptIn1: [
				{
					ProductService: ['BATHROOM_REFACING', 'WALK_IN_SHOWERS']
				},
				{
					BathProjectScope: [
						'Shower Conversion',
						'Bathtub / Shower Updates',
						'Bathtub Liner / Shower Enclosure',
						'Walk-in Shower'
					]
				}
			]
		},
		PLUMBING: {
			PlumbingProjectScope: [
				{
					ServiceDetails: ['DRAINS', 'WATER_HEATERS', 'WATER_TREATMENT', 'SEWER', 'WATER_MAIN']
				}
			],
			DrainageIssue: [
				{
					ServiceDetails: ['PLUMBING', 'WATER_HEATERS', 'WATER_TREATMENT', 'SEWER', 'WATER_MAIN']
				}
			],
			ProjectScope: [
				{
					ServiceDetails: ['PLUMBING', 'DRAINS', 'WATER_TREATMENT', 'SEWER', 'WATER_MAIN']
				}
			],
			ServiceType: [
				{
					ServiceDetails: ['PLUMBING', 'DRAINS', 'WATER_HEATERS', 'SEWER', 'WATER_MAIN']
				}
			]
		},
		TREE_SERVICES: {
			TreeSize: [
				{
					JobType: ['Arborist Report', 'Stump Removal', 'Land Clearing', 'Landscape Maintenance', 'Other']
				}
			]
		}
	};

	if (skipStepVertical && config[skipStepVertical]) {
		result = config[skipStepVertical];
	} else if (vertical && config[vertical]) {
		result = config[vertical];
	}

	return result;
}
