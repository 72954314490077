import { getDefaultSiteByDomain } from '../helpers';
/**
 * Check if current domain is eligible for HRC
 *
 * @returns {Boolean} - true if domain is eligible for HRC
 */
export function isQsSiteEligibleForHrc() {
	const eligibleDomains = [
		'modernize.local',
		'modernize.com',
		'airconditioningpros.org',
		'americanhomewarranty.org',
		'compareflooringprices.com',
		'bathandshower.org',
		'bathandshowerpros.com',
		'emedicalalerts.com',
		'findyourwindows.com',
		'flooringpricer.com',
		'foundationandbasements.com',
		'furnacereplacementcosts.org',
		'gutterguardcovers.com',
		'homesecurityprices.org',
		'homesolarrebate.com',
		'homewarrantyplans.org',
		'homewatersystemspros.com',
		'homewindowprices.org',
		'hvaccostguide.com',
		'hvacpros.org',
		'kitchencabinetrefacing.org',
		'kitchensandcabinets.com',
		'leafgutterpros.com',
		'lifemedicalalerts.com',
		'localbathremodelcost.com',
		'localgaragedoorpros.org',
		'modernizesolar.com',
		'modernize-solar.com',
		'mygutterguards.com',
		'mysolarcost.com',
		'plumbingservicecosts.com',
		'quotes.improvementcenter.com',
		'roofing-survey.com',
		'roofingcostguide.com',
		'roofingcosts.org',
		'roofingreplacementcost.com',
		'securitypricer.com',
		'sidingreplacementcost.com',
		'solarpanelpricing.com',
		'stairliftchairs.org',
		'thesidingremodelconnection.com',
		'tophottubs.org',
		'topinsulationpros.com',
		'treetrimmingservicepros.com',
		'walkinbathtubs.org',
		'walkintubforseniors.com',
		'walkintubprices.org',
		'windowreplacementcost.org',
		'windowscostguide.com',
		'windowspromos.com',
		'windowsremodelconnection.com',
		'windowssurvey.com'
	];

	return eligibleDomains.indexOf(getDefaultSiteByDomain()) > -1;
}
