/* globals modUtils */
export function getHSGeoLookupDetails() {
	let data = {};

	if (window.geolocation) {
		const { geo } = modUtils.getUrlParamsToObject();
		const { responseCollection, source, usedSource } = window.geolocation;

		if (responseCollection) {
			data = responseCollection;
		}

		if (responseCollection.maxmind) {
			const maxmind = responseCollection.maxmind;
			const filteredMaxmindObj = {
				city: maxmind.city?.names?.en || undefined,
				continent: maxmind.continent?.names?.en || undefined,
				country: maxmind.country?.names?.en || undefined,
				location: maxmind.location,
				postalCode: maxmind.postal?.code || undefined,
				subdivision: maxmind.subdivisions?.[0]?.names?.en || undefined
			};

			data.maxmind = filteredMaxmindObj;
		}

		if (geo) {
			data.geo = geo;
		}

		if (source || usedSource) {
			data.usedSource = usedSource || source;
		}
	}

	return data;
}
