/**
 * Get the next step id
 *
 * @param {string} newStepId - current step id
 * @returns {string} - next step id
 */
export function getNextStepId(newStepId) {
	const $nextSibling = document.getElementById(newStepId).nextElementSibling;
	if ($nextSibling) {
		return $nextSibling.getAttribute('id');
	}
}
