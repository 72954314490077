/**
 * Hide all step DOM elements.
 */
export function hideAllSteps() {
	window.modForm.opts.steps.forEach(function(step) {
		if (step.classList.contains(window.modForm.opts.stepActiveCls)) {
			step.classList.remove(window.modForm.opts.stepActiveCls);
		}
		if (window.modForm.opts.addAccessibilityUpdates && !window.modForm.opts.useGlobalInitCarousel) {
			step.setAttribute('aria-hidden', 'true');
			step.setAttribute('tabindex', '-1');
		}
	});
}
